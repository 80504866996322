<template>
  <div class="paystore">
    <v-row class="pa-6 mt-4">
      <v-btn-toggle v-model="tap" class="transparent" mandatory>
        <v-btn class="ma-0" active-class="rouge--text text--rouge v-btn--active" :value="0"
               text outlined style="width: 110px; height: 40px">카드 등록</v-btn>
        <v-btn class="ma-0" active-class="rouge--text text--rouge v-btn--active" :value="1"
            text outlined style="width: 110px; height: 40px">플랜 선택</v-btn>
        <v-btn class="ma-0" active-class="rouge--text text--rouge v-btn--active" :value="2"
               text outlined style="width: 110px; height: 40px" v-if="user.email !== 'test@supermembers.co.kr'">프리미엄패키지</v-btn>
        <v-btn class="ma-0" active-class="rouge--text text--rouge v-btn--active" :value="3"
            text outlined style="width: 110px; height: 40px">결제 내역</v-btn>
      </v-btn-toggle>
    </v-row>
    <v-row class="ml-1 pa-6">
      <CardReg v-if="tap === 0" :option="option" :cards="cards"></CardReg>
      <v-col cols="12" v-if="tap === 1">
        <div class="body-1 font-weight-bold mt-6 mb-2">결제플랜선택</div>
        <v-data-table :headers="[{ text: '광고명', width: '136px', sortable: false},
                      { text: '결제수단', width: '126px', sortable: false},
                      { text: '결제플랜', width: '126px', sortable: false},
                      { text: '결제금액', width: '76px', sortable: false}]"
                      :items="planList" hide-default-footer
                      mobile-breakpoint="0"
                      style="backgroud-color: transparent; border-top: 1px solid rgba(0,0,0,0.12); border-bottom: 1px solid rgba(0,0,0,0.12)">
          <template v-slot:item="props">
            <tr>
              <td :style="$vuetify.breakpoint.smAndDown ? '' : 'width: 136px'">{{ props.item.name }}</td>
              <td>
                {{ (props.item.card || {}).card_name ? ((props.item.card || {}).card_name + '('
                  +(props.item.card || {}).last4 + ')') : '미등록' }}
                <v-btn color="rouge" class="ma-0" flat outlined
                       @click="showChangeCard=true; selectItem=props.item; selectCard=(props.item.card ? parseInt(props.item.card.id) : null)"
                       :disabled="props.item.payMethod === 'cash'"
                       :style="$vuetify.breakpoint.smAndDown ? 'min-width: 47px; width: 47px' : 'max-width: 47px;max-height: 32px'">
                  <span>변경</span>
                </v-btn>
              </td>
              <td>
                {{ (props.item.plan || '1') + '개월플랜'}}
                <v-btn color="rouge" class="ma-0 ml-2" flat outlined @click="showChangePlan=true; selectItemPlan=props.item; selectPlan=(parseInt(props.item.plan) || 1)"
                       :style="$vuetify.breakpoint.smAndDown ? 'min-width: 47px; width: 47px' : 'max-width: 47px;max-height: 32px'"
                       :disabled="props.item.plan === 3 || props.item.category.firstName === '제품'"
                       v-if="user.email !== 'test@supermembers.co.kr'">
                  <span>변경</span>
                </v-btn>
              </td>
              <td class="pa-0">
                {{props.item.price | numFormat}}원
              </td>
            </tr>
          </template>
        </v-data-table>
        <v-row class="justify-space-between mt-6 py-2 pr-2 plan" v-if="user.email !== 'test@supermembers.co.kr'">
          <v-col cols="12" md="8" class="my-2 ml-3 white-two--text">
            <span class="subheading font-weight-bold">이제 결제 플랜을 선택하실 수 있습니다.</span><br>
            더 저렴한 가격에 슈퍼멤버스를 이용해보세요.
          </v-col>
          <v-col cols="12" md="3" class="text-right my-2">
            <v-btn class="secondary white-two--text ma-0 mr-3" flat @click="showPlan=true"
                   :style="'max-width: 160px'">
              <!--              <v-icon>add</v-icon>-->
              <span>지금확인하기</span>
            </v-btn>
          </v-col>
        </v-row>
        <v-row justify="center">
          <v-dialog v-model="showPlan" content-class="white-two" persistent max-width="664px">
            <v-card class="pa-4">
              <v-card-title>
                <v-row justify="space-between" align="center">
                  <h2 class="mt-2 font-weight-bold headline">매장 플랜 안내</h2>
                  <span class="secondary--text body-1 font-weight-bold" @click="showPlan=false">닫기</span>
                </v-row>
              </v-card-title>
              <v-card-text class="pt-0">
                <span>슈퍼멤버스를 오랫동안 사용하실 예정이라면, 플랜을 변경해 할인혜택을 받아보세요.</span>
                <v-row class="pt-4">
                  <v-col cols="12" md="4" class="my-4 px-2">
                    <v-row style="border-radius: 8px; box-shadow: 0 4px 8px 0 rgba(72, 72, 72, 0.1);">
                      <v-col class="pa-4" style="background-image: linear-gradient(130deg, #fcfeff, #ebeff2 40%, #edf2f5 99%);"
                             :style="$vuetify.breakpoint.mdAndUp ? 'min-height:172px; border-radius: 8px 8px 0px 0px' : 'min-height:180px; border-radius: 8px 0px 0px 8px'">
                        <span class="font-weight-bold">1개월 구독</span>
                        <div class="text-right mt-8">
                          <div>월 부담 비용</div>
                          <div :class="$vuetify.breakpoint.mdAndUp ? 'display-1' : 'headline'"><span :class="$vuetify.breakpoint.mdAndUp ? 'title' : 'subheading'">₩</span>{{planPrice[0].amount | numFormat}}</div>
                          <div class="caption">VAT 포함</div>
                        </div>
                      </v-col>
                      <v-col class="pa-4 caption" style="min-height: 196px">
                        <div class="mb-2">• 슈퍼멤버스 기본 플랜입니다.</div>
                        <div v-if="showPlanType === '실속형'" class="mb-2">• 월 이용 20명으로 제한됩니다.</div>
                        <div v-else-if="showPlanType === '표준형'">
                          <div class="mb-2">• 월 최대 100명까지 이용이 가능합니다.</div>
                          <div class="mb-2">• 월 평균 50-100명 이용됩니다.</div>
                        </div>
                        <div v-else class="mb-2">• 월 8명의 이용을 보장합니다.</div>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="12" md="4" class="px-2">
                    <v-row style="border-radius: 8px; box-shadow: 0 8px 24px 0 rgba(78, 193, 242, 0.5);">
                      <v-col class="white--text pa-4" style="background-image: linear-gradient(132deg, #22ddd9, #2abbee 37%, #2299f2)"
                             :style="$vuetify.breakpoint.mdAndUp ? 'min-height:172px; border-radius: 8px 8px 0px 0px' : 'min-height:180px; border-radius: 8px 0px 0px 8px'">
                        <div class="font-weight-bold caption warm-grey--text" :style="$vuetify.breakpoint.mdAndUp ? 'margin-left:80px' : 'margin-left:32px'"
                             style="margin-top: -34px; width: 87px; height: 36px; border-radius: 18px; background-color: #ffcd39; text-align: center; vertical-align: middle; line-height: 36px;">
                          <v-icon>bookmark_border</v-icon>추천플랜
                        </div>
                        <span class="font-weight-bold">6개월 구독</span>
                        <div class="text-right mt-12">
                          <div>월 부담 비용</div>
                          <div :class="$vuetify.breakpoint.mdAndUp ? 'display-1' : 'headline'"><span :class="$vuetify.breakpoint.mdAndUp ? 'title' : 'subheading'">₩</span>{{planPrice[1].month | numFormat}}</div>
                          <div class="caption">VAT 포함, {{planPrice[1].discount}}% 할인</div>
                        </div>
                      </v-col>
                      <v-col class="pa-4 caption" style="min-height: 204px">
                        <div class="mb-2">• 6개월간 이용하실 수 있는 {{planPrice[1].discount}}% 할인 플랜입니다.</div>
                        <div v-if="showPlanType === '실속형'" class="mb-2">• 매월 이용은 20명으로 제한됩니다.</div>
                        <div v-else-if="showPlanType === '표준형'" class="mb-2">• 월 최대 100명까지 이용이 가능합니다.</div>
                        <div v-else class="mb-2">• 월 8명, 총 48명의 이용을 보장합니다.</div>
                        <div class="mb-2">• 기간 내 1회, 30일간 광고 일시중지가 가능합니다.</div>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="12" md="4" class="mt-4 px-2">
                    <v-row style="border-radius: 8px; box-shadow: 0 4px 8px 0 rgba(72, 72, 72, 0.1);">
                      <v-col class="white--text pa-4" style="background-image: linear-gradient(130deg, #2df298, #23d089 60%, #23c473);"
                             :style="$vuetify.breakpoint.mdAndUp ? 'min-height:172px; border-radius: 8px 8px 0px 0px' : 'min-height:180px; border-radius: 8px 0px 0px 8px'">
                        <span class="font-weight-bold">12개월 구독</span>
                        <div class="text-right mt-8">
                          <div>월 부담 비용</div>
                          <div :class="$vuetify.breakpoint.mdAndUp ? 'display-1' : 'headline'"><span :class="$vuetify.breakpoint.mdAndUp ? 'title' : 'subheading'">₩</span>{{planPrice[2].month | numFormat}}</div>
                          <div class="caption">VAT 포함, {{planPrice[2].discount}}% 할인</div>
                        </div>
                      </v-col>
                      <v-col class="pa-4 caption" style="min-height: 196px">
                        <div class="mb-2">• 12개월간 이용하실 수 있는 {{planPrice[2].discount}}% 할인 플랜입니다.</div>
                        <div v-if="showPlanType === '실속형'" class="mb-2">• 매월 이용은 20명으로 제한됩니다.</div>
                        <div v-else-if="showPlanType === '표준형'" class="mb-2">• 월 최대 100명까지 이용이 가능합니다.</div>
                        <div v-else class="mb-2">• 월 8명, 총 96명의 이용을 보장합니다.</div>
                        <div class="mb-2">• 기간 내 2회, 각 30일간 광고 일시중지가 가능합니다.</div>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>
        <v-row justify="center">
          <v-dialog v-model="showChangePlan" content-class="white-two" persistent max-width="522px">
            <v-card class="pa-4">
              <v-card-title>
                <div>
                  <v-icon color="default" @click="showChangePlan=false">close</v-icon>
                  <br>
                  <h2 class="mt-2 font-weight-bold headline">결제 플랜 변경</h2>
                </div>
              </v-card-title>
              <v-card-text class="pt-0">
                <v-data-table :headers="[{ text: '', sortable: false},
                          { text: '', sortable: false},
                          { text: '', sortable: false}]"
                              :items="plans" hide-default-header hide-default-footer mobile-breakpoint="0"
                              style="backgroud-color: transparent; border-top: 1px solid rgba(0,0,0,0.12); border-bottom: 1px solid rgba(0,0,0,0.12)">
                  <template v-slot:item="props">
                    <tr>
                      <td class="pa-0">
                        <v-radio-group v-model="selectPlan" :mandatory="false">
                          <v-radio :value="props.item.plan" class="ml-4 mt-4"></v-radio>
                        </v-radio-group>
                      </td>
                      <td class="px-0">{{ props.item.plan + '개월 플랜'}}</td>
                      <td class="px-0 secondary--text">{{ props.item.month | numFormat }}원/월</td>
                      <td class="px-0"><span v-if="props.item.total" class="warm-grey--text" style="text-decoration: line-through;">{{ props.item.total | numFormat }}원 </span>{{ props.item.amount | numFormat }}원 결제</td>
                    </tr>
                  </template>
                </v-data-table>
                <v-row justify="end">
                  <span class="rouge--text">{{selectPlan}}개월 플랜</span><span>이 {{selectItem.date}}에 결제됩니다.</span>
                </v-row>
              </v-card-text>
              <v-card-actions>
                <v-btn class="rouge white-two--text" flat @click="showChangePlan=false; changePlan()">변경</v-btn>
                <v-btn color="rouge" outlined flat @click.native="showChangePlan=false">취소</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>
        <v-row justify="center">
          <v-dialog v-model="showChangePlanComplete" content-class="white-two" persistent max-width="500px">
            <v-card class="pa-4">
              <v-card-title>
                <div>
                  <v-icon color="default" @click="showChangePlanComplete=false">close</v-icon>
                  <br>
                  <h2 class="mt-2 font-weight-bold headline">결제 플랜 변경 완료</h2>
                </div>
              </v-card-title>
              <v-card-text class="pt-0">
                <span class="rouge--text">{{selectItem.name + '/' + selectPlan + '개월 플랜이'}}</span><br>
                <span class="rouge--text">{{selectItem.date}}</span><span>에 결제될 예정입니다.</span>
              </v-card-text>
              <v-card-actions>
                <v-btn class="rouge white-two--text" flat @click="showChangePlanComplete=false; changePlanComplete()">완료</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>
        <v-row justify="center">
          <v-dialog v-model="showChangeCard" content-class="white-two" persistent max-width="370px">
            <v-card class="pa-4">
              <v-card-title>
                <div>
                  <v-icon color="default" @click="showChangeCard=false">close</v-icon>
                  <br>
                  <h2 class="mt-2 font-weight-bold headline">결제 수단 변경</h2>
                </div>
              </v-card-title>
              <v-card-text class="pt-0">
                <v-data-table :headers="[{ text: '사용카드', sortable: false},
                          { text: '카드명', sortable: false},
                          { text: '카드번호', sortable: false}]"
                              :items="cards" hide-default-footer mobile-breakpoint="0"
                              style="backgroud-color: transparent; border-top: 1px solid rgba(0,0,0,0.12); border-bottom: 1px solid rgba(0,0,0,0.12)">
                  <template v-slot:header="props">
                    <tr class="px-0 mx-0">
                      <th v-for="header in props.headers" :key="header.text" class="px-0 mx-0">
                        {{ header.text }}
                      </th>
                    </tr>
                  </template>
                  <template v-slot:item="props">
                    <tr>
                      <td class="px-0">
                        <v-radio-group v-model="selectCard" :mandatory="false">
                          <v-radio :value="props.index" class="ml-4 mt-4"></v-radio>
                        </v-radio-group>
                      </td>
                      <td class="px-0">{{ props.item.card_name }}</td>
                      <td class="px-0">{{ props.item.last4 }}</td>
                    </tr>
                  </template>
                </v-data-table>
              </v-card-text>
              <v-card-actions>
                <v-btn class="rouge white-two--text" flat @click="showChangeCard=false; changeCard()">변경</v-btn>
                <v-btn color="rouge" outlined flat @click.native="showChangeCard=false">취소</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>
      </v-col>
      <v-col cols="12" v-if="tap === 2">
        <v-row>
          <v-col cols="6" class="pr-4 hidden-sm-and-down">
            <h2 class="headline">프리미엄 패키지 구매</h2>
            <v-divider class="my-4" width="36" />
            <div>
              <span class="text-center mt-10">패키지를 구매하시면 인원을 추가 하실 수 있습니다.</span>
            </div>
            <div>
              <span class="text-center">현금 결제만 가능하며, 하단 카톡채널로 충전 요청을 부탁드립니다.</span>
            </div>
          </v-col>
          <v-col cols="12" md="3">
            <h2 class="headline">모집 가능 인원</h2>
            <v-divider class="my-4" width="36" />
            <div>
              <span class="display-1 secondary--text text-center mt-10">{{ (premiumPackages?.[0]?.balance || 0) | numFormat}}명</span>
            </div>
          </v-col>
          <v-col cols="12" class="pr-4 hidden-md-and-up mt-4">
            <div>
              <span class="text-center mt-10">패키지를 구매하시면 인원을 추가 하실 수 있습니다.</span>
            </div>
            <div>
              <span class="text-center">현금 결제만 가능하며, 하단 카톡채널로 충전 요청을 부탁드립니다.</span>
            </div>
          </v-col>
        </v-row>
        <v-row class="mb-4 mt-10">
          <v-col cols="6" md="4" class="px-1">
            <v-row class="pa-3" style="border-radius: 8px;border: 1px solid rgba(0,0,0,0.12);width: 205px;height: 292px">
              <v-col class="ml-2">
                <v-chip color="rouge" class="text-center mt-2" outlined small>체험형 패키지</v-chip>
                <v-row class="my-2 subheading">인당 50,000원</v-row>
                <v-row class="my-1 caption">기간 12개월</v-row>
                <v-row class="my-1 caption">인원 5명</v-row>
                <v-row class="my-1 caption">가격 25만원</v-row>
                <v-row class="my-1 caption" style="color:#808080">*인당 원고료 20,000 포함</v-row>
                <v-row class="my-1 caption" style="color:#808080">*최소 5명 단위 캠페인</v-row>
                <v-row class="my-1 caption" style="color:#808080">*VAT 별도</v-row>
                <v-btn class="rouge white-two--text mx-0" style="width: 163px" @click="pay(250000, 'premium')">구매하기</v-btn>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="6" md="4" class="px-1 mb-2">
            <v-row class="pa-3 pt-7" style="border-radius: 8px;border: 1px solid #EA2A3A;width: 205px;height: 292px">
              <v-col class="ml-2" :style="$vuetify.breakpoint.mdAndUp ? 'min-height:172px; border-radius: 8px 8px 0px 0px' : 'min-height:180px; border-radius: 8px 0px 0px 8px'">
                <div class="caption white--text" :style="$vuetify.breakpoint.mdAndUp ? 'margin-left:60px' : 'margin-left:32px'"
                     style="margin-top: -44px; width: 55px; height: 27px; border-radius: 18px; background-color: #EA2A3A; text-align: center; vertical-align: middle; line-height: 27px;">
                  BEST
                </div>
                <v-chip color="rouge" class="text-center mt-2" outlined small>100만원 패키지</v-chip>
                <v-row class="my-2 subheading rouge--text">인당 40,000원</v-row>
                <v-row class="my-1 caption">기간 <span class="rouge--text">&nbsp;12개월</span></v-row>
                <v-row class="my-1 caption">인원 <span class="rouge--text">&nbsp;25명</span></v-row>
                <v-row class="my-1 caption">가격 100만원</v-row>
                <v-row class="my-1 caption" style="color:#808080">*인당 원고료 20,000 포함</v-row>
                <v-row class="my-1 caption" style="color:#808080">*최소 5명 단위 캠페인</v-row>
                <v-row class="my-1 caption" style="color:#808080">*VAT 별도</v-row>
                <v-btn class="rouge white-two--text mx-0" style="width: 163px" @click="pay(1000000, 'premium')">구매하기</v-btn>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="6" md="4" class="px-1">
            <v-row class="pa-3" style="border-radius: 8px;border: 1px solid rgba(0,0,0,0.12);width: 205px;height: 292px">
              <v-col class="ml-2">
                <v-chip color="rouge" class="text-center mt-2" outlined small>300만원 패키지</v-chip>
                <v-row class="my-2 subheading">인당 30,000원</v-row>
                <v-row class="my-1 caption">기간 12개월</v-row>
                <v-row class="my-1 caption">인원 100명</v-row>
                <v-row class="my-1 caption">가격 300만원</v-row>
                <v-row class="my-1 caption" style="color:#808080">*인당 원고료 20,000 포함</v-row>
                <v-row class="my-1 caption" style="color:#808080">*최소 5명 단위 캠페인</v-row>
                <v-row class="my-1 caption" style="color:#808080">*VAT 별도</v-row>
                <v-btn class="rouge white-two--text mx-0" style="width: 163px" @click="pay(3000000, 'premium')">구매하기</v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <div class="body-1 font-weight-bold mt-6 mb-2">
              충전•환불•소진 내역
            </div>
            <v-data-table
                :headers="packageHeaders"
                :items="premiumPackages"
                item-key="id"
                class="mt-3"
                disable-sort
                :items-per-page="5"
                mobile-breakpoint="0"
                style="backgroud-color: transparent; border-top: 1px solid rgba(0,0,0,0.12); border-bottom: 1px solid rgba(0,0,0,0.12)"
            >
              <template v-slot:item.status="{ item }">
                {{
                  item.adId ? '소진' : '충전'
                }}
              </template>
              <template v-slot:item.content="{ item }">
                {{ item.name || '-' }}
              </template>
              <template v-slot:item.number="{ item }">
                {{ (item.adId ? -item.number : item.number) || '-' }}명
              </template>
              <template v-slot:item.balance="{ item }">
                {{ item.balance | numFormat }}명
              </template>
              <template v-slot:item.price="{ item }">
                ₩{{ Math.abs(item.price) | numFormat }}
              </template>
              <template v-slot:item.createdAt="{ item }">
                {{ $moment(item.createdAt).format('YYYY.MM.DD') }}
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" v-if="tap === 3">
        <v-col v-if="payState === 1">
          <v-col cols="12">
            <h2 class="font-weight-bold headline hidden-sm-and-down">결제 상세 내역을<br>확인해주세요.</h2>
          </v-col>
          <v-col>
            <div class="body-1 font-weight-bold mt-6 mb-2">결제 내역 확인</div>
            <v-list v-for="(pay, index) in payList" :key="index">
              <v-list-item class="pa-0 ma-0 title">
                <v-list-item-content class="mx-0" style="width: 200px">{{pay.title}}</v-list-item-content>
                <v-list-item-action class="mx-0 text-right">월{{pay.price.toLocaleString()}}원</v-list-item-action>
              </v-list-item>
              <v-list-item class="pa-0 ma-0">
                <v-list-item-content class="mx-0" style="width: 200px">결제 예정일</v-list-item-content>
                <v-list-item-action class="mx-0 rouge--text">{{pay.date}}</v-list-item-action>
              </v-list-item>
              <v-list-item class="pa-0 ma-0">
                <v-list-item-content class="mx-0" style="width: 200px">월 결제 금액 (부가세 10% 포함)</v-list-item-content>
                <v-list-item-action class="mx-0 rouge--text">월{{(pay.price * 1.1).toLocaleString()}}원
                </v-list-item-action>
              </v-list-item>
            </v-list>
            <v-row>
              <v-col cols="12" md="5" class="estimate mt-2">
                <bulbIcon class="light_bulb"/>
                <div>
                  첫 결제 후 매월 같은 날에<br>
                  결제가 진행됩니다.
                </div>
              </v-col>
            </v-row>
            <v-btn block color="primary" class="ma-0 mt-12" @click="$store.commit('setPayState', 2)">결제수단 등록</v-btn>
          </v-col>
        </v-col>
        <v-row>
          <v-col cols="12">
            <div class="body-1 font-weight-bold mt-6 mb-2">결제 내역</div>
            <v-data-table :headers="[{ text: '광고명', width: '136px', sortable: false},
                          { text: '이용 건수', width: '80px', sortable: false},
                          { text: '기간', width: '120px', sortable: false},
                          { text: '결제일', width: '120px', sortable: false}]"
                          :items="paycardsSorted" :items-per-page="5" mobile-breakpoint="0"
                          style="backgroud-color: transparent; border-top: 1px solid rgba(0,0,0,0.12); border-bottom: 1px solid rgba(0,0,0,0.12)">
              <template v-slot:item="props">
                <tr style="font-size: 13px" v-if="props.item">
                  <td class="pr-2 pl-0" style="width: 250px">{{ props.item.order }}차]{{props.item.channel === 'insta' ?
                    '인스타그램' : '네이버블로그'}}/{{props.item.adsname}}
                  </td>
                  <td class="px-1">{{ (props.index === 0) ? props.item.contractCount + '(진행중)' :
                    props.item.contractCount }}
                  </td>
                  <td class="px-1">{{ $moment(props.item.adsStart).tz('Asia/Seoul').format('YYYY-MM-DD') }} -
                    {{props.item.nextPaidAt}}
                  </td>
                  <td class="pl-2 pr-0">{{ $moment(props.item.paidAt || props.item.adsStart).tz('Asia/Seoul').format('YYYY-MM-DD') }}</td>
                </tr>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <div class="body-1 font-weight-bold mt-6 mb-2">프리미엄 패키지</div>
            <v-data-table :headers="[{ text: '광고명', width: '136px', sortable: false},
                          { text: '희망 인원', width: '80px', sortable: false},
                          { text: '신청 건수', width: '80px', sortable: false},
                          { text: '진행 여부', width: '80px', sortable: false},
                          { text: '시작일', width: '120px', sortable: false},
                          { text: '종료일', width: '120px', sortable: false}]"
                          :items="paycardsSortedPremium" :items-per-page="5" mobile-breakpoint="0"
                          style="backgroud-color: transparent; border-top: 1px solid rgba(0,0,0,0.12); border-bottom: 1px solid rgba(0,0,0,0.12)">
              <template v-slot:item="props">
                <tr style="font-size: 13px" v-if="props.item">
                  <td class="pr-2 pl-0" style="width: 250px">{{ props.item.order }}차]{{props.item.channel === 'insta' ?
                      '인스타그램' : '네이버블로그'}}/{{props.item.adsname}}
                  </td>
                  <td class="px-1">{{ props.item.checkBloggerCnt}}</td>
                  <td class="px-1">{{ (props.index === 0) ? props.item.contractCount + '(진행중)' :
                      props.item.contractCount }}
                  </td>
                  <td class="pl-2 pr-0">{{ props.item.contractCount >= props.item.checkBloggerCnt ? '완료' : '진행중'}}</td>
                  <td class="px-1">{{ $moment(props.item.adsStart).tz('Asia/Seoul').format('YYYY-MM-DD') }}
                  </td>
                  <td class="pl-2 pr-0">{{ props.item.nextPaidAt }}</td>
                </tr>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
  import companyMixin from '@/components/shared/companyMixin'
  import userMixin from "@/components/shared/userMixin";
  import CardReg from "@/components/company/pay/cardreg/cardreg.vue";
  import BootPay from "bootpay-js";
  import {EventBus} from "@/components/shared/event-bus";

  export default {
    name: 'paystore',
    components: {
      CardReg,
      bulbIcon: () => import('@/assets/img/regads/light-bulb.svg')
    },
    mixins: [userMixin, companyMixin],
    props: ['option', 'cards'],
    data() {
      return {
        packageHeaders: [
          { text: '상태', value: 'status', width: '60px' },
          { text: '내용', value: 'content', width: '150px' },
          { text: '인원', value: 'number', width: '80px' },
          { text: '모집 가능 인원', value: 'balance', width: '110px' },
          { text: '결제 금액', value: 'price', width: '136px' },
          { text: '일시', value: 'createdAt', width: '136px' }
        ],
        selectItem: {},
        selectCard: 0,
        payList: [],
        paycards: [],
        paycardsPremium: [],
        showChangeCard: false,
        tap: 0,
        planList: [],
        showPlan: false,
        showChangePlan: false,
        showPlanType: "매장",
        selectItemPlan: {},
        selectPlan: 1,
        showChangePlanComplete: false,
        planPrice: [{}, {}, {}],
        premiumPackages: []
      }
    },
    watch: {
      option() {
        this.updatePayList();
        this.updatePlanList();
        this.planPrice = this.getPlanPrice();
      },
      showPlanType() {
        this.planPrice = this.getPlanPrice();
      }
    },
    computed: {
      paycardsSorted() {
        return this._.orderBy(this.paycards, [(card) => card.paidAt || card.adsStart], ['desc']);
      },
      paycardsSortedPremium() {
        return this._.orderBy(this.paycardsPremium, [(card) => card.paidAt || card.adsStart], ['desc']);
      },
      showStorePlan: function () {
        return this.companies.some((company) => {
          if (company.category.firstName === '매장') {
            return true;
          }
          return false;
        });
      },
      showProductPlan: function () {
        return this.companies.some((company) => {
          if (company.category.firstName === '제품') {
            return true;
          }
          return false;
        });
      },
      plans () {
        if (this.selectItemPlan && this.selectItemPlan.category) {
          if (this.selectItemPlan.category.firstName === '제품') {
            let total = this.option.price[this.selectItemPlan.channel].product[this.selectItemPlan.type + '1'];
            let total6 = this.option.price[this.selectItemPlan.channel].product[this.selectItemPlan.type + '6'];
            let total12 = this.option.price[this.selectItemPlan.channel].product[this.selectItemPlan.type + '12'];
            return [
              {plan: 1, month: total * 1.1, total: 0, amount: total * 1.1},
              {plan: 6, month: Math.floor((total6 / 6) * 1.1), total: Math.floor( total * 6 * 1.1), amount: Math.floor( total6 * 1.1)},
              {plan: 12, month: Math.floor((total12 / 12) * 1.1), total: Math.floor( total * 12 * 1.1), amount: Math.floor(total12 * 1.1)},
            ];
          } else {
            let total = this.option.price[this.selectItemPlan.channel]['store1'];
            let total6 = this.option.price[this.selectItemPlan.channel]['store6'];
            let total12 = this.option.price[this.selectItemPlan.channel]['store12'];
            return [
              {plan: 1, month: total * 1.1, total: 0, amount: total * 1.1},
              {plan: 6, month: Math.floor((total6 / 6) * 1.1), total: Math.floor( total * 6 * 1.1), amount: Math.floor(total6 * 1.1)},
              {plan: 12, month: Math.floor((total12 / 12) * 1.1), total: Math.floor( total * 12 * 1.1), amount: Math.floor(total12 * 1.1)},
            ];
          }
        }
        else {
          return [];
        }
      }
    },
    methods: {
      async changeCard() {
        let company = this.companies.filter((company) => (company.id === this.selectItem.companyId))[0];
        if (this.selectItem.type === 'naver') {
          company.naver.payInfo_card = parseInt(this.cards[this.selectCard].id);
        } else if (this.selectItem.type === 'insta') {
          company.insta.payInfo_card = parseInt(this.cards[this.selectCard].id);
        }

        await Promise.all(this.companies.map((company) => {
          let update = {};
          this._.merge(update, company.naver ? {naver: company.naver} : {}, company.insta ? {insta: company.insta} : {});
          if (this._.isEmpty(update)) {
            return null;
          } else {
            return this.axios.put('/company3?id=' + company.id, {...update, delay: true});
          }
        }));
        await this.$store.dispatch('getCompanyInfo', {email: this.user.email});
      },
      updatePayList() {
        if (this.option.price) {
          this.payList.splice(0, Number.MAX_VALUE);
          this.companies.forEach((company) => {
            let card = this.cards.filter((card) => company?.naver?.payInfo_card == card.id)[0];
            this.payList.push({
              title: '네이버블로그/' + company.name,
              date: company?.naver?.startDate,
              price: company?.naver?.type ? this.option.price?.naver?.product[company?.naver?.type] * (company?.naver?.number / 10) : this.option.price.naver['store' + (company?.naver?.payInfo_plan || 1)],
              card: card,
              payMethod: company?.naver?.payInfo_payMethod,
              companyId: company.id,
              type: 'naver'
            });
            if (company.insta) {
              let card = this.cards.filter((card) => company.insta.payInfo_card == card.id)[0];
              this.payList.push({
                title: '인스타그램/' + company.name,
                date: company.insta.startDate,
                price: company.insta.type ? this.option.price.insta.product[company.insta.type] * 1.1 : this.option.price.insta.store * 1.1,
                card: card,
                payMethod: company.insta.payInfo_payMethod,
                companyId: company.id,
                type: 'insta'
              })
            }
          })
        }
      },
      updatePlanList() {
        if (this.option.price) {
          this.planList.splice(0, Number.MAX_VALUE);
          this.companies.forEach((company) => {
            let card = this.cards.filter((card) => company?.naver?.payInfo_card == card.id)[0];
            ['naver'].forEach((channel) => {
              if (company[channel]) {
                this.planList.push({
                  name: company.name,
                  plan: company[channel]?.payInfo_plan || 1,
                  date: company[channel]?.startDate,
                  channel: channel,
                  companyId: company.id,
                  category: company.category,
                  type: company[channel]?.type,
                  card: card,
                  price: this.option.price?.naver?.store * 1.1,
                });
              }
            });
          })
        }
      },
      async changePlan() {
        let company = this.companies.filter((company) => (company.id === this.selectItemPlan.companyId))[0];
        if (this.selectItemPlan.channel === 'naver') {
          company.naver.payInfo_plan = parseInt(this.selectPlan);
        } else if (this.selectItemPlan.channel === 'insta') {
          company.insta.payInfo_plan = parseInt(this.selectPlan);
        }

        this.showChangePlanComplete = true;
      },
      async changePlanComplete() {
        await Promise.all(this.companies.map((company) => {
          let update = {};
          this._.merge(update, company.naver ? {naver: company.naver} : {}, company.insta ? {insta: company.insta} : {});
          if (this._.isEmpty(update)) {
            return null;
          } else {
            return this.axios.put('/company3?id=' + company.id, {...update, delay: true});
          }
        }));
        await this.$store.dispatch('getCompanyInfo', {email: this.user.email});
      },
      getPlanPrice () {
        if (this.option.price) {
          if (this.showPlanType === '매장') {
            let total = this.option.price['naver']['store1'];
            let total6 = this.option.price['naver']['store6'];
            let total12 = this.option.price['naver']['store12'];
            return [
              {plan: 1, month: total * 1.1, discount: 0, amount: total * 1.1},
              {
                plan: 6,
                month: Math.floor((total6 / 6) * 1.1),
                discount: ((Math.floor(total * 6) - Math.floor(total6)) * 100) / Math.floor(total * 6),
                amount: Math.floor(total6 * 1.1)
              },
              {
                plan: 12,
                month: Math.floor((total12 / 12) * 1.1),
                discount: ((Math.floor(total * 12) - Math.floor(total12)) * 100) / Math.floor(total * 12),
                amount: Math.floor(total12 * 1.1)
              },
            ]
          } else {
            let type = (this.showPlanType === '실속형') ? 'N' : 'S';
            let total = this.option.price['naver'].product[type + '1'];
            let total6 = this.option.price['naver'].product[type + '6'];
            let total12 = this.option.price['naver'].product[type + '12'];
            return [
              {plan: 1, month: total * 1.1, discount: 0, amount: total * 1.1},
              {
                plan: 6,
                month: Math.floor((total6 / 6) * 1.1),
                discount: ((Math.floor(total * 6) - Math.floor(total6)) * 100) / Math.floor(total * 6),
                amount: Math.floor(total6 * 1.1)
              },
              {
                plan: 12,
                month: Math.floor((total12 / 12) * 1.1),
                discount: ((Math.floor(total * 12) - Math.floor(total12)) * 100) / Math.floor(total * 12),
                amount: Math.floor(total12 * 1.1)
              },
            ];
          }
        }
        return [{}, {}, {}]
      },
      pay(price, type) {
        let payPrice = Math.floor(price * 1.1);
        console.log(this.user.id + '_' + type + '_' + new Date().getTime())
        BootPay.request({
          price: payPrice, //실제 결제되는 가격
          application_id: "5f20860202f57e0033305324",
          name: '인플루언서 마케팅 상품', //결제창에서 보여질 이름
          pg: 'inicis',
          method: 'card',
          show_agree_window: 0, // 부트페이 정보 동의 창 보이기 여부
          items: [
            {
              item_name: '인플루언서 마케팅 상품', //상품명
              qty: 1,
              unique: '0',
              price: payPrice,
              cat1: '마케팅',
              cat2: '마케팅',
              cat3: '마케팅',
            }
          ],
          user_info: {
            username: this.user.name,
            email: this.user.email,
            addr: '',
            phone: this.user.contacts.replace(/[^0-9]/g, '')
          },
          order_id: this.user.id + '_' + type + '_' + new Date().getTime(),
          params: {date: new Date(), companyId: this.user.id},
          extra: {
            theme: 'purple'
          }
        }).error((data) => {
          console.log(data);
          this.$store.commit('setMsg', data.msg);
          this.$store.commit('setShowSnackbar', true);
        }).cancel(async (data) => {
          console.log(data);
        }).confirm((data) => {
          if (data.params.companyId === this.user.id) {
            BootPay.transactionConfirm(data);
          } else {
            BootPay.removePaymentWindow();
          }
        }).close((data) => {
          console.log(data);
        }).done(async (data) => {
          let charge = {
            companyId: data.params.companyId,
            card: data.card_name,
            amount: price,//data.price,
            status: data.status,
            method: data.method,
            order: data.order_id,
            receiptId: data.receipt_id,
            receiptUrl: data.receipt_url
          }
          try {
            if (type === 'package') {
              charge.number = Math.floor({15:10, 100: 100, 500: 670, 1000: 2000}[price / 10000]);
              charge.period = {15: 1, 100: 6, 500: 12, 1000: 12}[price / 10000];
              charge.name = {15: '체험형 패키지', 100: '100만원 패키지', 500: '500만원 패키지', 1000: '1000만원 패키지'}[price / 10000];
              let res = await this.axios.post('company/user/package', charge);
              if (res.status === 200) {
                this.packages.unshift(res.data);
              }
            }
            else if (type === 'premium') {
              charge.number = Math.floor({25:5, 100: 25, 300: 100}[price / 10000]);
              charge.period = {25: 12, 100: 12, 300: 12}[price / 10000];
              charge.name = {25: '프리미엄 체험형 패키지', 100: '프리미엄 100만원 패키지', 300: '프리미엄 300만원 패키지'}[price / 10000];
              let res = await this.axios.post('company/user/premium', charge);
              if (res.status === 200) {
                this.premiumPackages.unshift(res.data);
              }
            }
            else {
              let res = await this.axios.post('/company/user/sumempoint', charge);
              if (res.status === 200) {
                this.points.unshift(res.data);
              }
            }
          }
          catch (e) {
            console.log(e)
          }
          if (this.user) {
            EventBus.$emit("update:point");
          }
        });
      }
    },
    async created() {
      let premiumPackages = await this.axios.get(
          `company/user/premium?companyId=${this.user.id}&orderBy=id&direction=desc`
      );
      this.premiumPackages = premiumPackages.data.packages;

      this.paycards.splice(0, Number.MAX_VALUE);
      this.companies.map(async (company) => {
        if (company.category.firstName === '매장') {
          let paycards = await this.axios.get('/company3/paycard?companyId=' + company.id);
          paycards.data.paycards.forEach((paycard) => {
            paycard.adsname = company.name;
            // this.$set(this.paycards, idx, paycard);
            if (this.$moment.tz('Asia/Seoul').isAfter(paycard.adsStart)) {
              if (company.type === 'A') {
                this.paycardsPremium.push(paycard);
              }
              else {
                this.paycards.push(paycard);
              }
            }
          });
        }
      });
      this.updatePayList();

      this.updatePlanList();
      this.planPrice = this.getPlanPrice();
    }
  };
</script>

<style scoped lang="scss">
.plan {
  border-radius: 8px;
  box-shadow: 0 6px 16px 0 rgba(15, 184, 134, 0.41);
  background-image: linear-gradient(96deg, #23c473, #23d089 40%, #2df298);

  .secondary {
    width: 110px;
    height: 40px;
    border-radius: 4px;
    background-color: #587cff !important;
  }
}
::v-deep .plantype .v-label {
  font-size: 14px;
}
</style>