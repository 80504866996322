<template>
  <v-col>
    <v-col v-if="$vuetify.breakpoint.mdAndUp" class="pt-0 pb-2">
      <v-btn-toggle v-model="tap" class="transparent" mandatory>
<!--        <v-btn class="ma-0" active-class="rouge&#45;&#45;text text&#45;&#45;rouge v-btn&#45;&#45;active" :value="0"-->
<!--          text outlined style="width: 234px; height: 50px">카드 등록</v-btn>-->
        <v-btn class="ma-0" active-class="rouge--text text--rouge v-btn--active" :value="0"
          text outlined style="width: 352px; height: 50px">매장</v-btn>
        <v-btn class="ma-0" active-class="rouge--text text--rouge v-btn--active" :value="1"
          text outlined style="width: 352px; height: 50px">제품</v-btn>
      </v-btn-toggle>
    </v-col>
    <v-col v-if="$vuetify.breakpoint.smAndDown" class="pt-0 px-4">
      <v-btn-toggle
        v-model="tap"
        class="transparent"
        mandatory
        style="width: 100%"
      >
        <!-- <v-btn
          class="ma-0"
          active-class="rouge--text text--rouge v-btn--active"
          :value="0"
          text
          outlined
          style="width: 33%; height: 50px"
          >카드 등록</v-btn
        > -->
        <v-btn
          class="ma-0"
          active-class="rouge--text text--rouge v-btn--active"
          :value="0"
          text
          outlined
          style="width: 50%; height: 50px"
          >매장</v-btn
        >
        <v-btn
          class="ma-0"
          active-class="rouge--text text--rouge v-btn--active"
          :value="1"
          text
          outlined
          style="width: 50%; height: 50px"
          >제품</v-btn
        >
      </v-btn-toggle>
    </v-col>
    <v-col class="content-page mt-2 pt-0">
<!--      <CardReg v-if="tap === 0" :option="option" :cards="cards"></CardReg>-->
      <PayStore v-if="tap === 0" :option="option" :cards="cards"></PayStore>
      <PayProduct v-if="tap === 1" :option="option"></PayProduct>
    </v-col>
  </v-col>
</template>

<script>
import userMixin from '@/components/shared/userMixin';
import companyMixin from '@/components/shared/companyMixin';

import PayStore from '@/components/company/pay/paystore/paystore';
import PayProduct from '@/components/company/pay/payproduct/payproduct';

export default {
  name: 'Pay',
  mixins: [userMixin, companyMixin],
  components: {
    PayStore,
    PayProduct,
  },
  data() {
    return {
      tap: 0,
      contracts: [],
      option: {},
      cards: [],
    };
  },
  computed: {},
  watch: {
    company(value) {
      this.getInitData(value);
    },
  },
  methods: {
    async getInitData(company) {
      this.axios
        .get(
          '/contract?company=' + company.id + '&orderBy=DATE(date0)&direction=desc'
        )
        .then((contracts) => {
          this.contracts = contracts.data.contracts;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  async created() {
    this.getInitData(this.company);

    let option = await this.axios.get('/company/option');
    this.option = option.data;

    let cards = await this.axios.get(
      '/company/user/card?userId=' + this.user.id
    );
    this.cards = cards.data.cards;
  },
};
</script>

<style scoped lang="scss">
::v-deep .v-list .title .v-list-item {
  padding: 0px;
  border-top: 1px solid #757575;
  border-top: 1px solid var(--warm-grey-color);
  border-bottom: 1px solid #757575;
  border-bottom: 1px solid var(--warm-grey-color);
  font-size: 14px;
}

::v-deep .v-list .v-list-item {
  padding: 0px;
  border-bottom: 1px solid #d3d3d3;
  font-size: 14px;
}

::v-deep .estimate {
  border-radius: 1px;
  background-color: #fcf2f3;
  padding: 10px;
}

::v-deep .light_bulb {
  width: 14px;
  height: 20px;
  object-fit: contain;
  background-color: #00000000;
}

::v-deep .v-text-field--outline.v-text-field--single-line input {
  margin-top: 0;
}

::v-deep .v-text-field--outline.v-text-field--single-line .v-input__slot {
  min-height: 40px;
  border-radius: 4px;
  border: solid 1px #c2c2c2;
  border: solid 1px var(--pinkish-grey-color);
}

::v-deep .card td[colspan='5'] {
  color: var(--rouge-color);
}

::v-deep .card td[colspan='5']::before {
  font-family: 'Material Design Icons'; //"Font Awesome 5 Free"
  content: '\f5d6'; //"\f06a"
  display: inline-block;
  padding-right: 3px;
  vertical-align: middle;
  font-weight: 900;
}
</style>
